import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { Device } from '@ionic-native/device/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Drivers } from '@ionic/storage';
import { File } from '@ionic-native/file/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { HttpClientModule } from '@angular/common/http'; 
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MatomoModule } from 'ngx-matomo';
import { Network } from '@ionic-native/network/ngx';
import { PinDialog } from '@ionic-native/pin-dialog/ngx';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { WebView } from '@ionic-native/ionic-webview/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MonthNamePipe } from './pipes/month-name.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [AppComponent, MonthNamePipe, SafeHtmlPipe],
  entryComponents: [],
  imports: [BrowserModule, CrystalLightboxModule, IonicModule.forRoot({
    mode: 'md',
    backButtonText: ''
  }), AppRoutingModule, 
    HttpClientModule, MatomoModule, FormsModule, ReactiveFormsModule,
    //IonicStorageModule.forRoot({
    //  name: '__communitydb', 
    //  driverOrder: [CordovaSQLiteDriver._driver, Drivers.LocalStorage]
    //})
    IonicStorageModule.forRoot() // leave defaults because SQLLiteDriver seems to have problem with browser refresh
  ],
  providers: [
    AndroidPermissions,
    AppVersion,
    AutocompleteLibModule,
    Calendar,
    Device,
    Dialogs,
    File,
    FingerprintAIO,
    FirebaseMessaging,
    InAppBrowser,
    Network,
    PinDialog,
    Platform, 
    SplashScreen,
    WebView,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
