import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'dynamic/:id',
    loadChildren: () => import('./pages/dynamic/dynamic.module').then( m => m.DynamicPageModule)
  },
  {
    path: 'authorise-add-pin',
    loadChildren: () => import('./pages/authorise-add-pin/authorise-add-pin.module').then( m => m.AuthoriseAddPinPageModule)
  },
  {
    path: 'authorise-email',
    loadChildren: () => import('./pages/authorise-email/authorise-email.module').then( m => m.AuthoriseEmailPageModule)
  },
  {
    path: 'authorise-key',
    loadChildren: () => import('./pages/authorise-key/authorise-key.module').then( m => m.AuthoriseKeyPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'settings-troubleshooting',
    loadChildren: () => import('./pages/settings-troubleshooting/settings-troubleshooting.module').then( m => m.SettingsTroubleshootingPageModule)
  },
  {
    path: 'notifications-inbox',
    loadChildren: () => import('./pages/notifications-inbox/notifications-inbox.module').then( m => m.NotificationsInboxPageModule)
  },
  {
    path: 'notifications-archive',
    loadChildren: () => import('./pages/notifications-archive/notifications-archive.module').then( m => m.NotificationsArchivePageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'notification-modal',
    loadChildren: () => import('./pages/notification-modal/notification-modal.module').then( m => m.NotificationModalPageModule)
  },
  {
    path: 'content-update',
    loadChildren: () => import('./pages/content-update/content-update.module').then( m => m.ContentUpdatePageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'meal-calendar',
    loadChildren: () => import('./pages/meal-calendar/meal-calendar.module').then( m => m.MealCalendarPageModule)
  },
  {
    path: 'meal-diet',
    loadChildren: () => import('./pages/meal-diet/meal-diet.module').then( m => m.MealDietPageModule)
  },
  {
    path: 'meal-request-add-edit',
    loadChildren: () => import('./pages/meal-request-add-edit/meal-request-add-edit.module').then( m => m.MealRequestAddEditPageModule)
  },
  {
    path: 'meal-requests',
    loadChildren: () => import('./pages/meal-requests/meal-requests.module').then( m => m.MealRequestsPageModule)
  },
  {
    path: 'meal-schedule',
    loadChildren: () => import('./pages/meal-schedule/meal-schedule.module').then( m => m.MealSchedulePageModule)
  },
  {
    path: 'roster-add-edit-shift',
    loadChildren: () => import('./pages/roster-add-edit-shift/roster-add-edit-shift.module').then( m => m.RosterAddEditShiftPageModule)
  },
  {
    path: 'roster-add-edit-shift/:id',
    loadChildren: () => import('./pages/roster-add-edit-shift/roster-add-edit-shift.module').then( m => m.RosterAddEditShiftPageModule)
  },
  {
    path: 'roster-agenda',
    loadChildren: () => import('./pages/roster-agenda/roster-agenda.module').then( m => m.RosterAgendaPageModule)
  },
  {
    path: 'roster-agenda/:id',
    loadChildren: () => import('./pages/roster-agenda/roster-agenda.module').then( m => m.RosterAgendaPageModule)
  },
  {
    path: 'roster-calendar',
    loadChildren: () => import('./pages/roster-calendar/roster-calendar.module').then( m => m.RosterCalendarPageModule)
  },
  {
    path: 'roster-check',
    loadChildren: () => import('./pages/roster-check/roster-check.module').then( m => m.RosterCheckPageModule)
  },
  {
    path: 'roster-manager',
    loadChildren: () => import('./pages/roster-manager/roster-manager.module').then( m => m.RosterManagerPageModule)
  },
  {
    path: 'roster-share',
    loadChildren: () => import('./pages/roster-share/roster-share.module').then( m => m.RosterSharePageModule)
  },
  {
    path: 'settings-roster',
    loadChildren: () => import('./pages/settings-roster/settings-roster.module').then( m => m.SettingsRosterPageModule)
  },
  {
    path: 'settings-roster-calendar',
    loadChildren: () => import('./pages/settings-roster-calendar/settings-roster-calendar.module').then( m => m.SettingsRosterCalendarPageModule)
  },
  {
    path: 'meal-current',
    loadChildren: () => import('./pages/meal-current/meal-current.module').then( m => m.MealCurrentPageModule)
  },
  {
    path: 'meal-credits',
    loadChildren: () => import('./pages/meal-credits/meal-credits.module').then( m => m.MealCreditsPageModule)
  },
  {
    path: 'meal-cafe-credits',
    loadChildren: () => import('./pages/meal-cafe-credits/meal-cafe-credits.module').then( m => m.MealCafeCreditsPageModule)
  },
  {
    path: 'authorise-help',
    loadChildren: () => import('./pages/authorise-help/authorise-help.module').then( m => m.AuthoriseHelpPageModule)
  },
  {
    path: 'roll-call',
    loadChildren: () => import('./pages/roll-call/roll-call.module').then( m => m.RollCallPageModule)
  },
  {
    path: 'roll-call-student/:id',
    loadChildren: () => import('./pages/roll-call-student/roll-call-student.module').then( m => m.RollCallStudentPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
